@import "../video.module.scss";

.episodics-container {
    position: relative;

    .episodics-counter {
        position: absolute;
        top: 1.5em;
        right: calc(0.2vw + 60px);
    }
}

.seasons-lister {
    display: grid;
    grid-template-columns: 0.245fr 1fr;
    grid-column-gap: 1rem;

    .seasons-lister-seasons {
        list-style-type: none;
        padding: 0;
        margin-top: 2rem;

        li button {
            width: 100%;
            color: var(--bs-white);
            box-shadow: none;
            text-align: left;

            &:hover {
                background-color: rgba(255,255,255,0.125);
            }

            &.active {
                background-color: var(--bs-white);
                color: var(--bs-dark);
            }
        }
    }

    .seasons-lister-container {
        position: relative;
        max-height: 65vh;
        overflow-y: auto;

        .seasons-lister-header {
            margin-left: -0.425em;
            margin-bottom: 0.5em;
        }

        .seasons-lister-body {
            background-color: var(--bg-color);
            color: var(--bs-white);
            border: 0;

            .seasons-lister-episode-tile {
                min-height: 9em;
            }

            ion-icon {
                color: var(--bs-white);
            }

            table tr td:nth-child(1),
            table tr td:nth-child(2) {
                width: 4rem;
                padding-left: 1rem;
            }

            table tr td p {
                margin-bottom: 0;
            }
        }
    }
}

.episode-active {
    background-color: white;
    color: black;

    a {
        filter: invert(1);
    }
}

.episode-link {
    padding: 0.25rem;
    aspect-ratio: 2;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: black;
    background-size: cover;
    background-repeat: no-repeat;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        border-radius: 0;

        ion-icon {
            pointer-events: none;
        }
    }

    .episode-ep {
        position: absolute;
        bottom: -1.5em;
        padding: 0.25em 0;
        background-color: black;
        width: 100%;
        text-align: center;
    }

    .progress {
        position: absolute;
        bottom: -2em;
        width: 100%;
    }
}

.seasons-list .episode-link {
    aspect-ratio: 1.5;
    margin-bottom: 1.25em;
}

.episode-title {
    letter-spacing: 1px;

    ~p {
        line-height: 1.5;
    }
}

.episode-imdb-link {
    position: absolute;
    right: 0rem;
    top: -0.5rem;
}
