:global {
    .carousel-control-prev {
        left: -14rem;
    }

    .carousel-control-next {
        right: -14rem;
    }

    .carousel-item.active {
        margin-bottom: 4rem;
    }

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        transform: scale(4);
        margin-bottom: 3rem;
    }

    .carousel-indicators button {
        height: 6px !important;
        border-radius: 3px;
        border: 1px solid transparent !important;
    }
}

.video-scroller-title {
    margin-left: calc((var(--video-tile-x) + var(--video-tile-gap)) / 2);
}

.video-scroller {
    position: relative;

    .video-scroller-body {
        display: flex;
        flex-wrap: nowrap;
        column-gap: 1.25rem;
        overflow-x: hidden;
        transition: margin 520ms;
        margin-left: calc((var(--video-tile-x) + var(--video-tile-gap)) / 2);

        .scroller-page {
            display: flex;
        }

        .scroller-page-prev {
            font-size: unset;
        }

        .scroller-page-next {
            font-size: unset;
        }
    }

    .scroller-btn {
        position: absolute;
        top: 0;
        z-index: 99;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(var(--video-tile-x) / 2);
        visibility: hidden;

        button {
            height: 50%;
            width: 50%;
        }

        &:hover button {
            height: 75%;
            width: 75%;
        }
    }

    .scroller-btn button:hover {
        opacity: 0.75 !important;
    }

    .scroller-prev {
        left: 0;
        background: linear-gradient(90deg, var(--bg-color), transparent);

        button {
            background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .scroller-next {
        right: 0;
        background: linear-gradient(90deg, transparent, var(--bg-color));

        button {
            background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}

.video-scroller-footer button,
.scroller-btn button {
    border-radius: 0px;
    outline: none!important;
    border-style: none!important;
    box-shadow: none!important;

    :focus {
        outline: none!important;
        border-style: none!important;
        box-shadow: none!important;
    }
}

.video-scroller-footer {
    position: absolute;
    top: -3em;
    width: 100%;
    visibility: hidden;

    .video-scroller-pagination {
        width: 25%;
        text-align: right;
        margin-right: calc((var(--video-tile-x) + var(--video-tile-gap)) / 2 - 1px);
    }

    button {
        width: 1.5rem;
        opacity: 0.25 !important;
        // margin: 0.25rem;
        margin-left: 2px;
        padding-top: 0.125rem;
        padding-bottom: 0rem;
    }

    button.active {
        opacity: 0.65 !important;
    }
}

.video-scroller:hover .video-scroller-footer,
.video-scroller:hover .scroller-btn {
    visibility: visible;
}

.video-scroller-body-next {
    animation-duration: 3s;
    animation-name: slidein;
}

.video-scroller-body-prev {
    animation-duration: 3s;
    animation-name: slideout;
}

@keyframes slidein {
    from {
        margin-left: 100%;
    }
    
    to {
        margin-left: 0%;
    }
}

@keyframes slideout {
    from {
        margin-left: 0%;
    }
    
    to {
        margin-left: 100%;
    }
}

.peek-prev,
.peek-next {
    position: absolute;
    width: 296.75px;
    top: 0;
    z-index: 0;
    pointer-events: none;
}

.peek-prev {
    left: -22.5rem;
    mask-image: linear-gradient(90deg, transparent, var(--bg-color));
}

.peek-next {
    right: -22.5rem;
    mask-image: linear-gradient(90deg, var(--bg-color), transparent);
}
