:root {
  --palette-primary-darken-6: rgba(0, 103, 181, 1);
  --palette-primary-darken-10: rgba(0, 120, 212, 1);
  --palette-primary-darkened-6: 0, 103, 181;
  --palette-primary-darkened-10: 0, 120, 212;
  --palette-primary-shade-30: 184, 216, 255;
  --palette-primary-shade-20: 152, 198, 255;
  --palette-primary-shade-10: 121, 181, 255;
  --palette-primary: 0, 91, 161;
  --palette-primary-tint-10: 82, 143, 217;
  --palette-primary-tint-20: 73, 126, 191;
  --palette-primary-tint-30: 55, 96, 145;
  --palette-primary-tint-40: 34, 59, 89;
  --palette-neutral-100: 255, 255, 255;
  --palette-neutral-80: 225, 223, 221;
  --palette-neutral-70: 190, 187, 184;
  --palette-neutral-60: 161, 159, 157;
  --palette-neutral-30: 121, 119, 117;
  --palette-neutral-20: 96, 94, 92;
  --palette-neutral-10: 72, 70, 68;
  --palette-neutral-8: 59, 58, 57;
  --palette-neutral-6: 50, 49, 48;
  --palette-neutral-4: 41, 40, 39;
  --palette-neutral-2: 37, 36, 35;
  --palette-neutral-0: 32, 31, 30;
  --palette-error: rgba(184, 14, 28, 1);
  --palette-error-6: rgba(203, 15, 31, 1);
  --palette-error-10: rgba(232, 17, 35, 1);
  --palette-black-alpha-0: rgba(var(--palette-neutral-100), 0);
  --palette-black-alpha-2: rgba(var(--palette-neutral-100), 0.02);
  --palette-black-alpha-4: rgba(var(--palette-neutral-100), 0.04);
  --palette-black-alpha-6: rgba(var(--palette-neutral-100), 0.08);
  --palette-black-alpha-8: rgba(var(--palette-neutral-100), 0.12);
  --palette-black-alpha-10: rgba(var(--palette-neutral-100), 0.18);
  --palette-black-alpha-20: rgba(var(--palette-neutral-100), 0.29);
  --palette-black-alpha-30: rgba(var(--palette-neutral-100), 0.40);
  --palette-black-alpha-60: rgba(var(--palette-neutral-100), 0.57);
  --palette-black-alpha-70: rgba(var(--palette-neutral-100), 0.7);
  --palette-black-alpha-80: rgba(var(--palette-neutral-100), 0.86);
  --palette-black-alpha-100: rgba(var(--palette-neutral-100), 1);
  --palette-accent1-light: 68, 39, 38;
  --palette-accent1: 241, 112, 123;
  --palette-accent1-dark: 241, 112, 123;
  --palette-accent2-light: 57, 61, 27;
  --palette-accent2: 146, 195, 83;
  --palette-accent2-dark: 146, 195, 83;
  --palette-accent3-light: 67, 53, 25;
  --palette-accent3: 253, 185, 19;
  --palette-accent3-dark: 253, 185, 19;
  --background-color: rgba(var(--palette-neutral-0), 1);
  --communication-foreground: rgba(var(--palette-primary-shade-20), 1);
  --communication-background: rgba(var(--palette-primary), 1);
  --status-info-foreground: rgba(97, 168, 255, 1);
  --status-info-background: rgba(0, 120, 212, 1);
  --status-error-foreground: rgba(var(--palette-accent1), 1);
  --status-error-background: rgba(var(--palette-accent1-light), 1);
  --status-error-text: rgba(var(--palette-accent1), 1);
  --status-error-strong: rgba(var(--palette-accent1-dark), 1);
  --status-success-foreground: rgba(var(--palette-accent2-dark), 1);
  --status-success-background: rgba(var(--palette-accent2-light), 1);
  --status-success-text: rgba(127, 184, 0, 1);
  --status-warning-icon-foreground: rgba(177, 133, 37, 1);
  --status-warning-foreground: rgba(var(--palette-accent3), 1);
  --status-warning-background: rgba(var(--palette-accent3-light), 1);
  --status-warning-text: rgba(var(--palette-accent3), 1);
  --text-primary-color: rgba(var(--palette-neutral-100), .95);
  --text-secondary-color: rgba(var(--palette-neutral-100), .80);
  --text-disabled-color: rgba(var(--palette-neutral-100), .5);
  --text-on-communication-background: var(--text-primary-color);
  --border-subtle-color: rgba(var(--palette-neutral-100), .08);
  --callout-background-color: rgba(var(--palette-neutral-6), 1);
  --callout-filtered-background-color: rgba(var(--palette-neutral-0), 0.80);
  --callout-shadow-color: rgba(0, 0, 0, .4);
  --callout-shadow-secondary-color: rgba(0, 0, 0, .32);
  --panel-shadow-color: rgba(0, 0, 0, .67);
  --panel-shadow-secondary-color: rgba(0, 0, 0, .53);
  --focus-pulse-max-color: rgba(var(--palette-primary-shade-10), 0.55);
  --focus-pulse-min-color: rgba(var(--palette-primary-shade-10), 0.35);
  --focus-border-color: rgba(var(--palette-primary-shade-10), 1);
  --third-party-icon-filter: invert(100%);
  --build-output-error-color: rgb(233, 45, 61);
  --diff-color-original: rgba(255, 0, 0, 0.2);
  --diff-color-modified: rgba(155, 185, 85, 0.2);
  --component-label-default-color: rgba(var(--palette-neutral-10), 1);
  --component-label-default-color-hover: rgba(var(--palette-neutral-20), 1);
  --component-grid-row-hover-color: rgba(var(--palette-neutral-100), 0.04);
  --component-grid-selected-row-color: rgba(var(--palette-primary-tint-40), 1);
  --component-grid-focus-border-color: rgba(var(--palette-primary), 1);
  --component-grid-link-selected-row-color: var(--communication-foreground);
  --component-grid-link-hover-color: var(--communication-foreground);
  --component-grid-action-hover-color: rgba(var(--palette-neutral-8), 1);
  --component-grid-action-selected-cell-hover-color: rgba(var(--palette-primary-tint-30), 0.4);
  --component-grid-cell-bottom-border-color: rgba(var(--palette-neutral-8), 1);
  --component-grid-drag-source-color: rgba(var(--palette-neutral-0), 0.30);
  --search-match-background: rgba(92, 45, 145, 1);
  --search-selected-match-background: rgba(131, 0, 163, 1);
  --icon-folder-color: #dcb67a;
  --component-errorBoundary-border-color: rgba(var(--palette-accent1), 1);
  --component-errorBoundary-background-color: rgba(var(--palette-accent1-light), 1);
  --nav-header-background: var(--background-color);
  --nav-header-item-hover-background: rgba(var(--palette-neutral-100), 0.02);
  --nav-header-active-item-background: rgba(var(--palette-neutral-100), 0.08);
  --nav-header-text-primary-color: var(--text-primary-color);
  --nav-header-text-secondary-color: var(--text-secondary-color);
  --nav-header-text-disabled-color: var(--text-disabled-color);
  --nav-header-product-color: rgba(var(--palette-primary-shade-20), 1);
  --nav-vertical-background-color: rgba(var(--palette-neutral-8), 1);
  --nav-vertical-item-hover-background: rgba(var(--palette-neutral-100), 0.04);
  --nav-vertical-active-group-background: rgba(var(--palette-neutral-100), 0.06);
  --nav-vertical-active-item-background: rgba(var(--palette-neutral-100), 0.12);
  --nav-vertical-text-primary-color: var(--text-primary-color);
  --nav-vertical-text-secondary-color: var(--text-secondary-color);
  --component-menu-selected-item-background: rgba(var(--palette-neutral-100), 0.04);
  --component-htmlEditor-background-color: rgba(255, 255, 255, 1);
  --component-htmlEditor-foreground-color: rgba(0, 0, 0, 0.9);

  --bg-color: #141414;
  --color: rgba(0, 0, 0, 0.9);
}

html {
  font-size: 14px;
}

body,
.modal-content {
  background-color: var(--bg-color);
  color: var(--color);
  color: var(--text-primary-color, rgba(0, 0, 0, 0.9))
}

.modal-content .btn-close {
  filter: invert(1);
}

input,
select,
textarea {
  background-color: var(--nav-header-background, rgba(255, 255, 255, 1)) !important;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.95);
}

input:disabled {
  color: rgba(255, 255, 255, 0.5) !important;
  border-color: rgba(255, 255, 255, 0.5) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.no-transition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.clickable {
  cursor: pointer;
}

.vjs-error .vjs-error-display .vjs-modal-dialog-content {
  padding-top: 15%;
  font-size: 2rem;
  color: var(--bs-danger);
}

nav a.nav-link {
  white-space: nowrap;
}

.btn, .form-control {
  border-radius: 2px;
}

.btn.btn-primary,
.btn.btn-secondary {
  text-transform: uppercase;
  font-size: 85%;
  font-family: Netflix Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  letter-spacing: 0.5px !important;
  font-weight: bold;
  box-shadow: none !important;
}

.pointer-none {
  pointer-events: none;
}

#root header {
  opacity: 1;
  transition: opacity 250ms ease-out;
}

#root.video-playing header {
  position: absolute;
  width: 100%;
  opacity: 0;
}

#root.video-playing header:hover {
  opacity: 1;
}
