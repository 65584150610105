:global {
    .btn.hover,
    .btn:hover {
        background: none;
        box-shadow: none;
        text-decoration: none;
    }

    .imdbRatingPlugin {
        display: none !important;
    }

    .boxart-rounded,
    .boxart-rounded .fallback-text-container {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .lolomoRow.lolomoRow_title_card .rowTitle .aro-row-header {
        padding-bottom: .05em;
        padding-left: .1em;

        .see-all-link {
            margin-right: 0.5em;
        }
    }

    .rowTitle:not(a) .aro-row-header.more-visible {
        display: none !important;
    }

    .slider .handle.handleNext {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .slider .handle.handlePrev {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .slider .sliderMask .sliderContent.animating {
        pointer-events: none;
        transition: transform 0.75s ease 0s;
    }

    .ptrack-container .slider .row-with-x-columns.row-with-x-columns-wrapped {
        white-space: pre-wrap !important;
    }

    .ptrack-container .slider .row-with-x-columns.row-with-x-columns-wrapped .slider-item {
        margin-bottom: 1vw;
    }

    .slider .sliderMask .sliderContent .slider-item:first-child,
    .slider .sliderMask .sliderContent .slider-item:last-child {
        padding: 0 0.2vw !important;
    }

    .progress {
        height: unset;
        overflow: unset;
        font-size: unset;
        background-color: unset;
        border-radius: unset;
    }

    .title-card .boxart-container img {
        height: 100%;
        object-fit: cover;
    }
}

.title-card-container {
    position: relative;

    .video-meta {
        position: absolute;
        top: 0;
        width: 100%;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        overflow-x: wrap;
        white-space: nowrap;
        z-index: 1;

        :global {
            .badge:not(.ms-auto) {
                background-color: black;
                font-size: 10pt;
            }
        }

        .badge-imdb {
            padding-left: 4px;
            padding-right: 4px;
            max-height: calc(1em + 0.35em * 2);
            overflow: hidden;

            svg {
                height: 1.25em !important;
                margin-top: -3px;

                ~* {
                    margin-right: 4px;
                }
            }
        }

        /* Chromium */
        @media screen and (-webkit-min-device-pixel-ratio:0) {
            .badge-imdb {
                padding-top: 5px;
                padding-bottom: 3px;
            }
        }

        .badge-subs svg {
            color: var(--bs-white);
        }
    }

    .video-label {
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        color: var(--bs-white);
        font-weight: bold;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        display: inline-flex;
        width: 100%;
        justify-content: space-between;

        label {
            margin: 0.65rem 1rem 0.5rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .btn-details {
            color: var(--bs-white);
            float: right;
            background-color: rgba(0, 0, 0, 0.75);
            border-top-left-radius: 0;
            border-bottom-right-radius: 4px;
            border-top-right-radius: 0;
            min-height: 2.5em;
            box-shadow: none !important;
            display: flex;
            align-items: center;

            &:hover {
                background-color: black;
                color: var(--bs-white);
            }
        }
    }

    :global {
        .title-card:hover {
            box-shadow: 0em 0em 1em black;
        }

        .ptrack-content {
            border-bottom-color: black;
            border-bottom: 0;

            .slider-refocus {
                position: relative;

                &:hover {
                    border-color: var(--bs-white);

                    &::after {
                        content: var(--play-icon);
                        position: absolute;
                        top: calc(50% - 2.5rem);
                        left: calc(50% - 2.5rem);
                        height: 5rem;
                        width: 5rem;
                        filter: invert(1);
                    }
                }
            }
        }
    }

    ~.video-tags {
        margin-top: 0.5em;

        .btn-edit-tags {
            box-shadow: none !important;

            :global {
                .badge {
                    max-width: 1.5rem;
                }
            }

            ion-icon {
                pointer-events: none;
                margin-left: -1px;
            }
        }
    }
}

@media screen and (max-width:499px) {
    :global {
        .navbar .navbar-brand {
            display: none;
        }

        .navbar {
            font-size: 10pt;

            >:first-child.ps-5.pe-5 {
                padding-left: 0.5em !important;
                padding-right: 0.5em !important;
            }

            .navbar-collapse {
                flex-direction: column !important;

                > * {
                    width: 100%;
                    justify-content: space-between;
                }

                >:not(:last-child) {
                    margin-bottom: 0.5em;
                }

                .ms-auto {
                    margin-left: 0 !important;
                }

                .nav-link {
                    border: 1px solid #ffffff10;
                }

                form {
                    flex: 1 1 100%;
                }
            }
        }

        .rowHeader .rowTitle .row-header-title {
            font-size: 20pt!important;
        }

        .rowHeader {
            margin-bottom: 0.5em!important;
        }

        .rowContainer .rowContent .slider .row-with-x-columns {
            display: flex;
            flex-direction: column;
            padding-top: 2rem;

            .slider-item {
                width: 100% !important;
                font-size: 10pt;

                &:not(:last-child) {
                    margin-bottom: 0.5rem;
                }

                .ptrack-content {
                    .badge {
                        font-size: 8pt;

                        &:first-child {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .title-card-container {
        ~.video-tags {
            font-size: 1.25em;
        }
    }
}
