:global {
    .login-bg {
        background-image: url("/assets/login-background.png"), url("/assets/login-background-lite.png");
        width: 100vw;
        height: 100vh;
        position: relative;
    }
}

.login-island {
    background-color: black;
    position: absolute;
    top: 30%;
    left: 37%;
    height: 25%;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1000px) {
    .login-island {
        width: 50%;
        left: 26%;
        height: 32%;
    }
}

@media (max-height: 900px) {
    .login-island {
        height: 32%;
    }
}

@media (max-height: 700px) {
    .login-island {
        height: 50%;
    }
}

.login-island button {
    border: 1px solid;
}

.microsoft-logo {
    height: 1rem;
    margin-right: 0.25rem;
    margin-top: 2px;
}
