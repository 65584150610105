@import "../video.module.scss";
// TODO: Move out of here
@import "../videoscroller.module.scss";

:root {
    --video-link-color: rgb(255,255,91);
}

:global {
    .title-card-container {
        position: relative;
    }

    .table.table-hover {
        td {
            user-select: text;
        }

        tr:hover {
            background-color: rgba(255,255,255,0.2);
            color: var(--bs-text-light);
        }
    }
}

.badge-cc {
    background-image: url("../../assets/closed-captioning.svg");
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    color: transparent;
}

.video-credits {
    list-style: none;
    padding-left: 0;

    label {
        font-weight: bold;
    }

    >li {
        border-top: 1px solid rgba(255,255,255,0.2);
        padding-top: 1em;
        padding-bottom: 1em;
        display: flex;

        ul {
            list-style: none;
            padding-left: 0;

            li {
                display: inline;
            }

            a {
                color: var(--video-link-color);
                text-decoration: none;

                :hover {
                    text-decoration: underline;
                }
            }

            .credit-desc {
                margin-left: 0.5em;
            }

            li:not(:last-child)::after {
                content: "•";
                color: var(--text-primary-color, rgba(0, 0, 0, 0.9));
                margin-left: 0.5em;;
                margin-right: 0.5em;
            }
        }
    }

    >li:last-child {
        border-bottom: 1px solid rgba(255,255,255,0.2);
    }
}

@media screen and (max-width:499px) {
    :global {
        .table.table-hover {
            font-size: 1rem;
        }
    }
}