.navbar {
    background: var(--bg-color);
    color: rgba(0, 0, 0, 0.55);
    color: var(--nav-vertical-text-secondary-color, rgba(0, 0, 0, 0.55));
    border-bottom: 0 !important;

    .navbar-navs {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    :global(.navbar-brand),
    :global(.navbar-nav .nav-link) {
        color: var(--nav-vertical-text-secondary-color, rgba(0, 0, 0, 0.55)) !important;
    }

    :global(.navbar-brand) {
        padding-top: 0px;
        padding-bottom: 0px;
        font-family: Netflix Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
        display: flex;
        align-items: center;
    }

    :global(.app-logo) {
        height: 2rem;
        margin-right: 0.5rem;
        pointer-events: none;
    }

    .form-search {
        width: 100%;
        justify-content: center;

        input {
            border-color: rgb(48, 48, 48);
            box-shadow: none !important;
            font-size: 12pt;
            border-right-width: 0;

            &:focus {
                border-color: #1c62b9;
                border-right-width: 1px;
            }
        }

        .btn-submit {
            background: rgba(255, 255, 255, 0.08);
            color: var(--nav-vertical-text-secondary-color, rgba(0, 0, 0, 0.55));
            box-shadow: none !important;
            display: flex;
            align-items: center;
        }

        ion-icon {
            font-size: 14pt;
        }
    }
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

@media screen and (max-width: 768px) {
    .navbar-navs {
        display: flex !important;
        flex-direction: column;
        padding-left: 1.25rem !important;
        padding-right: 1.25rem !important;

        :global(.navbar-nav .nav-link) {
            width: 33%;
            text-align: center;
            justify-content: center;
        }
    }

    .form-search form {
        width: 100%;
        margin-top: 0.25em;
    }
}

@media screen and (min-width: 900px) {
    .form-search form {
        width: 33vw;
    }
}

@media screen and (min-width: 1200px) {
    .form-search form {
        width: 25vw;
    }
}

@media (prefers-reduced-motion: no-preference) {
    :global(.app-logo) {
        animation: app-logo-spin infinite 20s linear;
    }
}

@keyframes app-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}