:root {
    // 311.5px by 467.25
    --video-tile-x: 23.333vh;
    --video-tile-y: calc(var(--video-tile-x) * 3 / 2);
    --video-tile-gap: calc(var(--video-tile-x) / 18);
    --play-icon: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGNsYXNzPSJpb25pY29uIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHRpdGxlPlBsYXk8L3RpdGxlPjxwYXRoIGQ9Ik0xMTIgMTExdjI5MGMwIDE3LjQ0IDE3IDI4LjUyIDMxIDIwLjE2bDI0Ny45LTE0OC4zN2MxMi4xMi03LjI1IDEyLjEyLTI2LjMzIDAtMzMuNThMMTQzIDkwLjg0Yy0xNC04LjM2LTMxIDIuNzItMzEgMjAuMTZ6IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2Utd2lkdGg9IjMyIi8+PC9zdmc+");
    --play-icon-border: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='88' height='88' fill='%23fff'%3E%3Cpath fill-rule='evenodd' d='M44 88C19.738 88 0 68.262 0 44S19.738 0 44 0s44 19.738 44 44-19.738 44-44 44zm0-85C21.393 3 3 21.393 3 44c0 22.608 18.393 41 41 41s41-18.392 41-41C85 21.393 66.607 3 44 3zm16.063 43.898L39.629 60.741a3.496 3.496 0 01-3.604.194 3.492 3.492 0 01-1.859-3.092V30.158c0-1.299.712-2.483 1.859-3.092a3.487 3.487 0 013.604.194l20.433 13.843a3.497 3.497 0 01.001 5.795zm-1.683-3.311L37.946 29.744a.49.49 0 00-.276-.09.51.51 0 00-.239.062.483.483 0 00-.265.442v27.685c0 .262.166.389.265.442.1.053.299.118.515-.028L58.38 44.414A.489.489 0 0058.6 44a.49.49 0 00-.22-.413z'/%3E%3C/svg%3E");
}

.video-tile {
    border: 1px solid;
    border-color: var(--bg-color);
    color: var(--bs-white);
    width: var(--video-tile-x);
    height: var(--video-tile-y);
    display: flex;
    border-radius: 3px;
    align-items: flex-end;
    background-color: var(--background-color);
    background-size: cover;
    background-position: center center;
    position: relative;
    text-decoration: none;

    &.video-tile-loading {
        background-color: rgba(255, 255, 255, 0.23);
        border: 0 !important;
        opacity: 0.15;
        cursor: default;
        pointer-events: none;
    }

    [disabled] {
        opacity: 0.5;
        pointer-events: none;

        .not-available {
            position: absolute;
            top: calc(50% - 1rem);
            left: 0;
            width: 100%;
            text-align: center;
            text-shadow: black 0px 1px;
            font-size: 2rem;
        }
    }

    .video-label {
        width: 100%;
        background-color: rgba(0,0,0, 0.5);
        color: var(--bs-white);
        font-weight: bold;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        display: inline-flex;
        width: 100%;
        justify-content: space-between;

        label {
            pointer-events: none;
            margin: 0.5rem 1rem 0.5rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .button {
            color: var(--bs-white);
            float: right;
            background-color: rgba(0,0,0, 0.75);
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 1px;
            border-top-right-radius: 0;
            min-height: 2.5em;
        }
    }

    button:hover {
        background-color: black;
        color: var(--bs-white);
    }

    &:hover {
        border-color: var(--bs-white);
    }

    &:hover::after {
        content: var(--play-icon);
        position: absolute;
        top: calc(50% - 2.5rem);
        left: calc(50% - 2.5rem);
        height: 5rem;
        width: 5rem;
        filter: invert(1);
    }
}

.icon-cc {
    position: absolute;
    top: 2.5rem;
    left: 0.65rem;
    background-color: black;
    color: white;
    padding: 0 2px;
    border-radius: 2px;
}

.video-rating {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    max-width: var(--video-tile-x);
    overflow-x: wrap;
    white-space: nowrap;

    :global {
        .imdbRatingPlugin {
            padding: 3px 4px 0px 3px;
            margin: 0;

            img:not(.star) {
                margin-right: 5px;
            }

            img.star {
                display: none;
            }

            :only-child {
                display: none;
            }
        }

        .imdbRatingPlugin.imdbRatingStyle2 {
            margin-right: 0.25rem;
        }
        
        .imdbRatingPlugin:not(.imdbRatingStyle2) {
            display: none;
        }

        .imdbRatingPlugin:first-child {
            padding-top: 2px;
            padding-left: 3px;
            font-size: calc(var(--video-tile-x) / 20);
        }

        .badge:not(.ms-auto) {
            background-color: black;
            font-size: calc(var(--video-tile-x) / 20);
        }
    }
}

.video-tile button:focus,
.video-scroller button:focus {
    box-shadow: none;
}

.video-tags {
    margin-top: 0.25rem;
    max-width: var(--video-tile-x);
}

.video-tags-edit {
    max-width: 1.5rem;
    padding-left: 0.45rem;
    margin-top: 0.2rem;

    ion-icon {
        pointer-events: none;
    }
}

.video-error {
    position: absolute;
    z-index: 99;
    width: 96.7%;
    height: 90%;
    align-items: center;
    align-content: center;
    display: flex;
    justify-content: center;
}

video {
    background: black;
}

video.paused {
    box-shadow: inset 0rem 0rem 8rem 20px black;
}

.video-title {
    position: absolute;
    z-index: 99;
    transition: opacity 500ms;
    padding: 1rem 2rem;
}

.video-player {
    width: 100%;
    position: relative;
    max-height: calc(100vh - 9rem);
}

.video-grid-title {
    width: 100%;
}

.video-grid-title,
.video-grid {
    display: flex;
    flex-wrap: wrap;
    column-gap: var(--video-tile-gap);
    row-gap: 1rem;
    padding-left: calc(var(--video-tile-x) / 2 - 1.95rem);
    padding-right: calc(var(--video-tile-x) / 2 - 1.95rem);
}

.video-no-results {
    flex: 0 0 100%;
}

:global {
    #video-player::before {
        opacity: 0;
        transition: opacity 250ms ease-out;
        content: "";
        position: absolute;
        top: calc(50% - 12.5vh);
        left: 12.5vh;
        height: 25vh;
        width: 25vh;
        background: var(--bs-white);
        z-index: 99;
        mask-image: url(/assets/skip-backward-outline.svg);
        -webkit-mask-image: url(/assets/skip-backward-outline.svg);
    }

    #video-player.skip-backward::before {
        opacity: 1;
    }

    #video-player::after {
        opacity: 0;
        transition: opacity 250ms ease-out;
        content: "";
        position: absolute;
        top: calc(50% - 12.5vh);
        right: 12.5vh;
        height: 25vh;
        width: 25vh;
        background: var(--bs-white);
        z-index: 99;
        mask-image: url(/assets/skip-forward-outline.svg);
        -webkit-mask-image: url(/assets/skip-forward-outline.svg);
    }

    #video-player.skip-forward::after {
        opacity: 1;
    }

    .video-js {
        cursor: pointer;
        width: 100%;
        height: 100vh;

        .vjs-big-play-button {
            background-color: transparent !important;
            background: var(--play-icon-border) !important;
            border: 0 !important;
            background-position: center center !important;
            background-size: cover !important;
            height: 5em !important;
            width: 5em !important;
            top: calc(50% - 1.5em);
            left: calc(50% - 1.5em);
        }

        .vjs-loading-spinner {
            top: calc(50% - 1em);
            left: calc(50% - 1em);
            scale: 1.25;
            transform: scale(1.25);
        }

        &.vjs-paused .vjs-big-play-button,
        &.vjs-waiting .vjs-loading-spinner {
            display: inline-block !important;
        }

        &.vjs-error .vjs-loading-spinner,
        &.vjs-waiting .vjs-big-play-button {
            display: none !important;
        }

        &.vjs-paused:hover .vjs-big-play-button {
            scale: 1.25;
        }

        &.vjs-playing.vjs-user-inactive {
            cursor: none;
        }

        .vjs-control-bar {
            background-color: transparent;
            font-size: 2rem;
            margin-bottom: 0;
        }

        &.vjs-theme-forest .vjs-play-control .vjs-icon-placeholder::before {
            background-color: transparent !important;
        }

        .vjs-play-control .vjs-icon-placeholder::before {
            background-color: transparent !important;
            color: var(--bs-white) !important;
        }

        .vjs-volume-panel.vjs-control.vjs-volume-panel-horizontal {
            display: none;
        }

        .vjs-subtitles-menu-item {
            text-transform: capitalize !important;
        }

        .vjs-menu-content {
            color: var(--bs-white);
            text-shadow: none;
        }

        .vjs-menu-item.vjs-texttrack-settings {
            display: none;
        }
    }
}
